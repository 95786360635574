// Connects to data-controller="signature-pad"

import {Controller} from "@hotwired/stimulus"
import SignaturePad from 'signature_pad';

export default class extends Controller {
    static targets = [ "canvas", "signatureData", "submitButton" ]

    connect() {
        this.signaturePad = new SignaturePad(this.canvasTarget);
    }

    disconnect() {
    }

    clear(event) {
        this.signaturePad.clear();
        this.signatureDataTarget.value = '';
    }

    save(event) {
        if (this.signaturePad.isEmpty()) {
            alert("Please provide a signature first.");
        } else {
            alert("Saved signature.");
            this.signatureDataTarget.value = this.signaturePad.toDataURL();
        }
    }

    submit(event) {
        if (this.signaturePad.isEmpty() || !this.signatureDataTarget.value) {
            alert("Please provide a signature first.");
            event.preventDefault();
        }
    }
}